import React, { useState, useEffect } from "react";
import OrderSummary from './Essentials/OrderSummary/OrderSummary';
import AddOns from './Essentials/AddOns/AddOns';
import Address from './Essentials/Address/Address';
import DiscountCoupons from './Essentials/DiscountCoupons/DiscountCoupons';
import BillSummary from './Essentials/BillSummary/BillSummary';
import AddedAddOns from './Essentials/AddedAddOns/AddedAddOns';
import ProceedPayU from './Essentials/ProceedPayU/ProceedPayU';
import './PaymentPage.css';
import { ApiHitPost } from "../../Services/ApiHit";
import Loader from '../Includes/Loader/Loader';
import { useParams } from "react-router-dom";

function PaymentPage({ initType }) {
  // console.log("status of user");
  const [addedAddOns, setAddedAddOns] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [billSummaryData, setBillSummaryData] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialOrders, setInitialOrders] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [mandateAddOns, setMandateAddOns] = useState([]);
  const [addedAddonsIds, setAddedAddonsIds] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [addressesLoading, setAddressesLoading] = useState(true);
  const { productId, emiNo } = useParams();
  const [cart_product_ids, setCartProductIds] = useState();

  useEffect(() => {
    // Fetch product purchase data and user addresses on component mount
    const fetchProductPurchaseData = async () => {
      try {

        // Check if no logged in user, redirect on login page
        const dla_web_student_u = localStorage.getItem("dla_web_student_u");
        if (dla_web_student_u === null) {
          localStorage.setItem('dla_web_student_redirect_back_to_login',`/my-cart`);
          window.location.href = '/login';
        }

        // const check_if_puchased = ;

        const check_if_puchased = await ApiHitPost('/user/get_cart_list', { type: 1 });
        // const cart_product_ids = cartResponse.data.join(',');



        let initPayload = {};

        // Prepare payload based on the initType
        switch (initType) {
          case 1:
            setCartProductIds(productId);
            initPayload = { emi_no: 0, is_emi: false, product_ids: productId };
            break;
          case 2:
            setCartProductIds(productId);
            initPayload = { emi_no: Number(emiNo), is_emi: true, product_ids: productId };
            break;
          case 3:
            const cartResponse = await ApiHitPost('/user/get_cart_list', { type: 1 });
            const cart_product_ids = cartResponse.data.join(',');
            setCartProductIds(cart_product_ids);
            initPayload = { emi_no: 0, is_emi: false, product_ids: cart_product_ids };
            break;
          default:
            break;
        }

        // Fetch product purchase details
        const response = await ApiHitPost('/payment/get_product_purchase_details', initPayload);

        // Set state variables with the fetched data
        setInitialOrders(response.data.course_basic_information);
        setAddOns(response.data.all_addons);
        setMandateAddOns(response.data.mandatory_addons);
        setCoupons(response.data.all_valid_coupons);

        const productIdsForBillSummary = response.data.course_basic_information.map(val => val.id);
        const addedAddonsIds = response.data.mandatory_addons.map(val => val.id);

        // if (addedAddonsIds.length > 0) {
          setAddedAddonsIds(addedAddonsIds);
          fetchBillSummaryData(addedAddonsIds.join(','), productIdsForBillSummary.join(','), "", emiNo ? Number(emiNo) : 0, emiNo ? (Number(emiNo) > 0) : false);
        // }

        setLoading(false);
      } catch (error) {
        console.error('Failed to load product purchase data:', error);
        setLoading(false);
      }
    };

    const fetchUserAddressesData = async () => {
      try {
        const userAddress = await ApiHitPost('/user/get_address_list');
        setAddresses(userAddress.data);
        setAddressesLoading(false);
      } catch (error) {
        console.error('Failed to load addresses:', error);
        setAddressesLoading(false);
      }
    };

    fetchProductPurchaseData();
    fetchUserAddressesData();
  }, [productId, initType, emiNo]);

  const fetchBillSummaryData = async (addonIds = "", productIds, couponId = "", emiNo = 0, isEmi = false) => {
    try {
      const summaryResponse = await ApiHitPost('/payment/get_bill_summary', {
        addon_ids: addonIds,
        coupon_id: couponId,
        emi_no: emiNo,
        is_emi: isEmi,
        product_ids: productIds,
      });
      setBillSummaryData(summaryResponse.data);
      setTotalPrice(summaryResponse.data.net_payble_amount);
      setCoupons(summaryResponse.data.all_valid_coupons);
    } catch (error) {
      console.error('Failed to load bill summary data:', error);
    }
  };

  const handleAddOnToggle = (id) => {
    const updatedAddOns = addOns.map(addon => {
      if (addon.id === id) {
        addon.selected = !addon.selected;
        setAddedAddOns(prev => addon.selected ? [...prev, addon] : prev.filter(a => a.id !== id));
      }
      return addon;
    });

    
  
    setAddOns(updatedAddOns);
    
    const newAddedAddonsIds = updatedAddOns
      .filter(addon => addon.selected)
      .map(addon => addon.id);

      const mandateAddOnsIds = mandateAddOns.map(addon => addon.id);

      console.log("mandateAddOnsIdsmandateAddOnsIds",mandateAddOnsIds);

      const all_selected_addons = [...newAddedAddonsIds, ...mandateAddOnsIds];
  
    setAddedAddonsIds(all_selected_addons);
    const product_ids = initialOrders.map(order => order.id).join(",");
    if (initialOrders.length > 0) {
      fetchBillSummaryData(
        all_selected_addons.join(','),
        product_ids,
        "",
        emiNo ? Number(emiNo) : 0,
        emiNo ? (Number(emiNo) > 0) : false
      );
    }
  };
  

  const handleRemoveAddOn = (id) => {
    

    const updatedAddOns = addOns.map(addon => addon.id === id ? { ...addon, selected: false } : addon);
    
    setAddOns(updatedAddOns);

    const newAddedAddOns = addedAddOns.filter(addon => addon.id !== id);

    setAddedAddOns(newAddedAddOns);

    const newAddedAddonsIds = addedAddonsIds.filter(addonId => addonId !== id);

    setAddedAddonsIds(newAddedAddonsIds);

    if (initialOrders.length > 0) {
      // fetchBillSummaryData(newAddedAddonsIds.join(','), initialOrders[0].id.toString(), "", emiNo ? Number(emiNo) : 0, emiNo ? (Number(emiNo) > 0) : false);

      fetchBillSummaryData(newAddedAddonsIds.join(','), cart_product_ids.toString(), "", emiNo ? Number(emiNo) : 0, emiNo ? (Number(emiNo) > 0) : false);
    }
  };

  const handleApplyCoupon = (id) => {
    const coupon = coupons.find(c => c.coupon_id === id);
    setCoupons(coupons.map(
      (c) => {
        if(c.coupon_id === id){
          return { 
            ...c, 
            applied: true 
          }
        }else{
          return { 
            ...c, 
            applied: false
          }
        } 
      }
    ));
    setAppliedCoupon(coupon);

    console.log("cart_product_idscart_product_ids",cart_product_ids);
    if (initialOrders.length > 0) {
      fetchBillSummaryData(addedAddonsIds.join(','), cart_product_ids.toString(), coupon.coupon_id.toString(), emiNo ? Number(emiNo) : 0, emiNo ? (Number(emiNo) > 0) : false);
    }
  };

  const handleRemoveCoupon = (id) => {
    const coupon = coupons.find(c => c.coupon_id === id);
    setCoupons(coupons.map(
      (c) => {
        return { 
          ...c, 
          applied: false 
        } 
      }
    ));
    setAppliedCoupon(null);
    if (initialOrders.length > 0) {
      fetchBillSummaryData(addedAddonsIds.join(','), cart_product_ids.toString(), "", emiNo ? Number(emiNo) : 0, emiNo ? (Number(emiNo) > 0) : false);
    }
  };

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  const handleAddAddress = (newAddress) => {
    const newAddressWithId = { ...newAddress, id: addresses ? (addresses.length + 1) :1 };
    setAddresses(prevAddresses => [...prevAddresses, newAddressWithId]);
    setSelectedAddress(newAddressWithId);
  };

  return (
    <div className="payment-page">
      <h1>Payments</h1>
      <div className="payment-container">
        {loading ? <Loader /> : (
          <div className="payment-content">
            <div className="left-column">
              <OrderSummary orders={initialOrders} />
              <hr></hr>
              <AddOns addOns={addOns} onAddOnToggle={handleAddOnToggle} />
              <h3>Savings Corner</h3>
              <DiscountCoupons coupons={coupons} onApplyCoupon={handleApplyCoupon} handleRemoveCoupon={handleRemoveCoupon} appliedCoupon={appliedCoupon} />
            </div>
            <div className="right-column">
              <h2>Summary</h2>
              <AddedAddOns mandateAddOns={mandateAddOns} addedAddOns={addedAddOns} onRemoveAddOn={handleRemoveAddOn} />
              {!addressesLoading && (
                <Address
                  addresses={addresses}
                  onSelectAddress={handleSelectAddress}
                  onAddAddress={handleAddAddress}
                  selectedAddress={selectedAddress}
                />
              )}
              <BillSummary
                billSummaryData={billSummaryData}
                totalPrice={totalPrice}
                appliedCoupon={appliedCoupon}
                addedAddOns={addedAddOns}
                emiNo = {emiNo}
              />
              <ProceedPayU
                billSummaryData={billSummaryData}
                addresses={addresses}
                selectedAddress={selectedAddress}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentPage;
