import React, { useState } from 'react';
import { ApiHitPost } from '../../../../Services/ApiHit';
import './ProceedPayU.css';
import { useParams } from "react-router-dom";

const PaymentButton = ({ billSummaryData, addresses, selectedAddress }) => {
  const [loading, setLoading] = useState(false);
  const { productId, emiNo } = useParams();

  const handlePayment = async () => {
    setLoading(true);
    try {
      console.log("Bill summary data:", billSummaryData);

      // Prepare purchase components
      const purchaseComponents = billSummaryData.course_basic_information.map(data => ({
        emi_no: data.emi_no,
        product_id: data.course_id,
        product_price: data.course_selling_price,
      }));

      const couponId = billSummaryData.coupon_details?.coupon_id || 0;


      console.log("billSummaryData.course_basic_information",billSummaryData.course_basic_information);
      // return;
      const cartProductIds = billSummaryData.course_basic_information.map(val => val.course_id);

      // Validate addresses
      if (!addresses) {
        alert("Please add an address to proceed.");
        return;
      }

      // Initialize transaction
      const initResponse = await ApiHitPost('/payment/initialize_transaction', {
        address_id: selectedAddress ? selectedAddress.id : addresses[0].id,
        cart_value: billSummaryData.net_payble_amount,
        coupon_applied: couponId,
        pay_via: billSummaryData.pay_via,
        product_addons: billSummaryData.addons_data,
        purchase_components: purchaseComponents,
      });

      const txnid = initResponse.data.data.pre_txn_id;
      const hashArray = initResponse.data.data.hash_array;
      const hash = hashArray.payment_hash;

      console.log("Transaction initialization response:", initResponse);
      console.log("Hash array:", hashArray);

      if (initResponse) {
        // Ensure amount is properly formatted
        const amount = parseFloat(billSummaryData.net_payble_amount).toFixed(2);
        if (!amount || isNaN(amount)) {
          console.error("Invalid amount value:", billSummaryData.net_payble_amount);
          return;
        }

        console.log("Amount from web:", amount);

        if (billSummaryData.pay_via === 12) {
          const newAccessKeyResponse = await ApiHitPost('/payment/get_new_acceskey', {
            key: '02UD76GTIX',
            txnid,
            amount,
            productinfo: cartProductIds.join('-'),
            firstname: localStorage.getItem('dla_web_student_first_name'),
            phone: localStorage.getItem('dla_web_student_mobile_number') || '',
            email: localStorage.getItem('dla_web_student_email_id'),
            surl: "https://www.drishtilearningapp.com/api/payu-success",
            furl: "https://www.drishtilearningapp.com/api/payu-failed",
            hash,
          });

          if (newAccessKeyResponse) {
            window.location.href = `https://pay.easebuzz.in/pay/${newAccessKeyResponse.data}`;
          } else {
            window.location.href = "https://www.drishtilearningapp.com/";
          }

          return;
        } else {
            // console.log("Final Amiunt Send to PG",amount);
        //   const response = await ApiHitPost('/payment/create-pay-order', {
        //     amount: amount, // Amount in INR, ensure it's a number or string
        //     firstname: localStorage.getItem('dla_web_student_first_name'),
        //     email: localStorage.getItem('dla_web_student_email_id'),
        //     phone: localStorage.getItem('dla_web_student_mobile_number').toString(),
        //     productinfo: '3619',
        //     txnid: init_txnid,
        //     surl: 'https://www.drishtilearningapp.com/api/payu-success', // Success URL
        //     furl: 'https://www.drishtilearningapp.com/api/payu-failed'  // Failure URL
        //   });

        //   const { txnid, hash, payuUrl } = response;

        //   console.log("order hash",hash);
        //   console.log("init hash",init_hash);

          // Create and submit form to PAYU payment gateway
          const form = document.createElement('form');
          form.method = 'POST';
          form.action = "https://secure.payu.in/_payment";

          const fields = {
            key: 'hpMct5',
            txnid,
            amount,
            productinfo: cartProductIds.join(','),
            firstname: localStorage.getItem('dla_web_student_first_name'),
            email: localStorage.getItem('dla_web_student_email_id'),
            phone: localStorage.getItem('dla_web_student_mobile_number') || '',
            hash,
            surl: 'https://www.drishtilearningapp.com/api/payu-success', // Success URL
            furl: 'https://www.drishtilearningapp.com/api/payu-failed'  // Failure URL
          };

          Object.entries(fields).forEach(([key, value]) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value;
            form.appendChild(input);
          });

          document.body.appendChild(form);
          form.submit();
        }
      }
    } catch (error) {
      console.error('Error processing payment:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button className="continue-btn" onClick={handlePayment} disabled={loading}>
      {loading ? 'Processing...' : 'Continue Pay'}
    </button>
  );
};

export default PaymentButton;